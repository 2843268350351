<template>
  <ContentBase>
    首页
  </ContentBase>
</template>

<script>
import ContentBase from '@/components/ContentBase.vue';

export default {
  name: 'HomeView',
  components: {
    ContentBase,
  }
}
</script>

<style scoped>
</style>
