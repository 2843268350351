<template>
  <NavBar/>
  <!-- 判断完整链接，正常判断名字，而不是path -->
  <router-view :key="$route.fullPath"/> 
</template>

<script>
import NavBar from './components/NavBar.vue';
export default {
  name: "App",
  components: {
    NavBar,
  }
}
</script>

<style scoped>
</style>
