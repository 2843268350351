import { createStore } from 'vuex'
import ModuleUser from './user';

export default createStore({
  state: {
  },
  getters: { //获取
  },
  mutations: { // 赋值修改,不能异步
  },
  actions: { // 整体修改，可以异步
  },
  modules: { // 组件
    user: ModuleUser,
  }
});
